export const projectsData = [
  {
    id: '3',
    title: 'Choredo',
    summary:
      'This is a chore todo web app to track, complete, and manage chores. Users can browse and clone public chores from the community, and earn rewards for completing their reoccurring chores.\n\n Frontend React Vite web app that sends all requests to Choredo backend. A REST API that handles connecting to supabase for auth and table CRUD operations, and to PokeAPI for the achievement system.',
    tags: [
      'React',
      'Typescript',
      'Vite',
      'Tailwind',
      'Redux Toolkit',
      'Node.js',
      'ExpressJS',
      'REST API',
      'SQL',
      'Supabase',
      'Row Level Security',
      'User Auth Flows',
    ],
    videoOptions: {
      poster: `${process.env.PUBLIC_URL}/Choredo-Poster.png`,
      sources: [
        {
          src: `https://d2xhor4hji9wkc.cloudfront.net/Choredo.mp4`,
          type: 'video/mp4',
        },
      ],
    },
    projectLink: 'https://zackleaman.github.io/choredo-fe/',
    repositoryLink: 'https://github.com/ZackLeaman/choredo-fe',
  },
  {
    id: '0',
    title: 'Microlessons Engine',
    summary: '',
    tags: [
      'Angular',
      'Typescript',
      'HTML',
      'CSS',
      'Video.js',
      'VTT',
      'Jasmine / Karma',
      'Cypress',
      'KaTeX',
      'GraphQL',
      'Git / GitHub',
      'Responsive Design',
      'Accessibility',
    ],
    videoOptions: {
      poster: `${process.env.PUBLIC_URL}/HTEC-VID-POSTER.png`,
      sources: [
        {
          src: `https://d2xhor4hji9wkc.cloudfront.net/HTEC_VID.mp4`,
          type: 'video/mp4',
        },
      ],
    },
  },
  {
    id: '1',
    title: 'Early Math Content',
    summary: '',
    tags: [
      'Java',
      'TortoiseSVN',
      'TestRail',
      'O.O. Programming',
      'Event-Driven Architecture',
      'Collaborative Team',
    ],
    videoOptions: {
      poster: `${process.env.PUBLIC_URL}/ISTATION-VID-POSTER.png`,
      sources: [
        {
          src: `https://d2xhor4hji9wkc.cloudfront.net/ISTATION_VID.mp4`,
          type: 'video/mp4',
        },
      ],
    },
  },
  {
    id: '2',
    title: 'Horse Racing Simulation',
    summary:
      'This project was generated with Angular CLI version 13.0.3. It is created as a demonstration of Genetic Algorithms and Neural Networks. A horse race is the theme where the top 3 fittest horses birth the next generation with the chance of mutations. The top 3 fittest horses also participate in the next race for fitness comparison with their children. This project uses the Angular Framework in unison with Phaser 3 to create game states as well as a visual representation of the horses learning to be better racers. Currently, there is no traditional means of saving horses for future use, but one can get the current horses in JSON form (typically I CTRL+A and then CTRL+C to copy and paste to a text document). One can then paste in JSON, and upload their own custom horse data. There is also expert horses that come built into the web app, which one can use by pressing the "Upload Expert Horses" and then "Play" buttons. At the end of the race, the top 3 horses in the RESULTS display are the fittest/winners.',
    projectLink: 'https://zackleaman.github.io/',
    repositoryLink:
      'https://github.com/ZackLeaman/Neural-Network-Genetic-Algorithm-Horse-Racing-App/tree/main',
    tags: [
      'Phaser 3',
      'Angular',
      'TypeScript',
      'HTML',
      'CSS',
      'Machine Learning',
    ],
    videoOptions: {
      poster: `${process.env.PUBLIC_URL}/HorseRacingSim.PNG`,
      sources: [
        {
          src: `https://d2xhor4hji9wkc.cloudfront.net/CS50FinalProjectOverview.mp4`,
          type: 'video/mp4',
        },
      ],
    },
  },
];
